
import authService from "./AuthorizeService";

export class HttpService {
    // Unused:
    // public BaseUrl: string = "http://localhost:5000/";
    // public BaseUrl: string = "backend/";
    // public BaseUrl: string = window.location.origin + ":5000/"; // Works on AWS
    // public BaseUrl: string = "http://147board.com:5000/";



    // Actually used:
    // public BaseUrl: string = "https://localhost:44385/"; // Windows localhost   
    public BaseUrl: string = "https://147board.com/";

    getBaseUrlForLink(): string {
        if (this.BaseUrl.includes("localhost")) {
            return "http://localhost:3000/";
        }
        return this.BaseUrl;
    }

    async anonFetch(apiPath: string): Promise<Response> {
        const response = await fetch(`${this.BaseUrl}${apiPath}`);
        return response;
    }

    async anonGet<T>(apiPath: string): Promise<T> {
        const response = await fetch(`${this.BaseUrl}${apiPath}`);
        if (response.ok) {
            let data = await response.json();
            return data;
        }
        else {
            throw new Error("Not found");            
        }
    }

    async get<T>(apiPath:string): Promise<T> {
        const token = authService.getBearerToken();
        const response = await fetch(`${this.BaseUrl}${apiPath}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        let data = await response.json();
        return data;
    };

    async post<T>(apiPath: string, obj: T): Promise<T> {
        const token = authService.getBearerToken();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(obj)
        };

        const response = await fetch(`${this.BaseUrl}${apiPath}`, requestOptions);
        const data = await response.json();
        return data;
    };

    async postWithReturn<Trequest, Tresponse>(apiPath: string, obj: Trequest): Promise<Tresponse> {
        const token = authService.getBearerToken();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(obj)
        };

        const response = await fetch(`${this.BaseUrl}${apiPath}`, requestOptions);
        const data = await response.json();
        return data;
    };

    async postWithId<T>(apiPath: string, id: string, obj: T): Promise<Response> {
        const token = authService.getBearerToken();
        const body = JSON.stringify(obj);
        console.log(body);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: body
        };

        const response = await fetch(`${this.BaseUrl}${apiPath}/${id}`, requestOptions);
        return response;
    };
    
    async upload<T>(apiPath: string, file: FormData): Promise<T> {
        const token = authService.getBearerToken();
        const requestOptions = {
            method: 'POST',
            headers: {  'Accept': 'application/json', 'Authorization': `Bearer ${token}` },
            body: file
        };

        const response = await fetch(`${this.BaseUrl}${apiPath}`, requestOptions);
        const data = await response.json();
        return data;
    }

    async revokeLicense<T>(apiPath: string, id: string, obj: T): Promise<boolean> {
        const token = authService.getBearerToken();
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(obj)
        };

        const response = await fetch(`${this.BaseUrl}${apiPath}/${id}/revoke`, requestOptions);
        // const data = await response.json();
        return response.ok;
    }

    async put<T>(apiPath: string, id: string, obj: T): Promise<boolean> {
        const token = authService.getBearerToken();
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(obj)
        };

        const response = await fetch(`${this.BaseUrl}${apiPath}/${id}`, requestOptions);
        // const data = await response.json();
        return response.ok;
    }

    async delete<T>(apiPath: string, id: string): Promise<T> {
        const token = authService.getBearerToken();
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        };

        const response = await fetch(`${this.BaseUrl}${apiPath}/${id}`, requestOptions);
        const data = await response.json();
        return data;
    }
}

const httpService = new HttpService();

export default httpService;